import 'ol/ol.css';
import TopoJSON from 'ol/format/TopoJSON';
import Map from 'ol/Map';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import View from 'ol/View';
import {Fill, Stroke, Style, Text} from 'ol/style';
import OSM from 'ol/source/OSM';
import {fromLonLat} from 'ol/proj';
import {ScaleLine, Zoom} from 'ol/control';

var plzStyle = new Style({
    text: new Text({
        font: '11px sans-serif',
        placement: 'point',
        fill: new Fill({
            color: [0, 0, 150, .75]
        })
    }),
    fill: false,
    stroke: new Stroke({
        color: [0, 0, 200, .75],
        width: 1
    })
});

var plzStyler = function(feature) {
    plzStyle.getText().setText(feature.get('plz'));
    return plzStyle;
};

var mainMap = new Map({
    target: 'map',
    controls: [
        new ScaleLine(),
        new Zoom(),
    ],
    view: new View({
        center: fromLonLat([10, 51.5]),
        zoom: 6,
        minZoom: 4,
        maxZoom: 18
    }),
    layers: [
        new TileLayer({
            source: new OSM({
                attributions: false
            })
        }),
        new VectorLayer({
            minZoom: 10,
            maxZoom: 12,
            source: new VectorSource({
                format: new TopoJSON(),
                url: 'data/plz-de.1.js'
            }),
            style: plzStyler
        }),
        new VectorLayer({
            minZoom: 12,
            maxZoom: 14,
            source: new VectorSource({
                format: new TopoJSON(),
                url: 'data/plz-de.2.js'
            }),
            style: plzStyler
        }),
        new VectorLayer({
            minZoom: 14,
            maxZoom: 18,
            source: new VectorSource({
                format: new TopoJSON(),
                url: 'data/plz-de.3.js'
            }),
            style: plzStyler
        })
    ]
});